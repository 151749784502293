// Projects.js

import React from 'react';
import projectImage from '../foodoro.png';
import todoImage from '../todo.png'; // Import your project image
import './Projects.css';


function Projects() {
    return (
        <section id="projects">
            <h2>Projects</h2>

            <div className="project">
                <h3 className="project-title">Development of autonomous robot.</h3>
                <p className="project-description">
                    In the course "Development of Intelligent Systems", our team embarked on an ambitious project to design and construct an autonomous robot
                    with a suite of interactive capabilities. Our robot was engineered to simulate a range of human-like
                    interactions, making it a standout venture in exploring the realms of artificial intelligence and robotics.
                    Our team of three won the challenge for most accurate and functional robot. Task included many aspect of AI like computer vision, NLP, speech synthesis and more.
                </p>
                <div className="project-image">
                    <img src={projectImage} alt="Project description" />
                </div>
                <div className="project-image">
                    <img src={todoImage} alt="Project description" />
                </div>
                <a href="https://github.com/deadmau5p/rins_tas3" className="project-link" target="_blank" rel="noopener noreferrer">
                    View on GitHub
                </a>
            </div>

            <div className="project">
                <h3 className="project-title">Stance Detection in Slovenian News Media</h3>
                <p className="project-description">
                    Analyzing the bias in Slovenian news media regarding political-ideological topics and individuals frequently featured within. This project aims
                    to categorize articles into classes (against, for, neutral) according to the authors' inclination towards a particular topic or individual.
                </p>
                <a href="https://github.com/deadmau5p/graduate-thesis" className="project-link" target="_blank" rel="noopener noreferrer">
                    View on GitHub
                </a>
            </div>

        </section>
    );
}

export default Projects;
