// Footer.js

import React from 'react';
import githubLogo from '../github.png';
import linkedinLogo from '../linkedin.png';
import './Footer.css';


function Footer() {
    return (
        <footer>
            <div className="social-links">
                <a href="https://www.linkedin.com/in/alja%C5%BE-poto%C4%8Dnik-70325365/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinLogo} alt="LinkedIn" />
                </a>
                <a href="https://github.com/deadmau5p" target="_blank" rel="noopener noreferrer">
                    <img src={githubLogo} alt="GitHub" />
                </a>
            </div>
            <p>&copy; 2023 Aljaž Potočnik</p>
        </footer >
    );
}

export default Footer;
