import linkedinLogo from '../linkedin.png';
import './Header.css';

function Header() {
    return (
        <header>
            <div className="header-content">
                <div className="title">
                    <h1>Aljaž Potočnik</h1>
                    <h2>NLP Engineer</h2>
                </div>
                <nav>
                    <a href="#about">About</a>
                    <a href="#projects">Projects</a>
                    <a href="#skills">Skills</a>
                </nav>
                <div className="linkedin-link">
                    <a href="https://www.linkedin.com/in/alja%C5%BE-poto%C4%8Dnik-70325365/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinLogo} alt="LinkedIn" />
                    </a>
                </div>
            </div>
        </header>
    );
}

export default Header;