import React from 'react';

import './Skills.css';

function Skills() {
    return (
        <section id="skills">
            <h2>Skills</h2>
            <div className="skills-grid">
                <div className="skill">PyTorch</div>
                <div className="skill">NumPy</div>
                <div className="skill">Python</div>
                <div className="skill">Pandas</div>
                <div className="skill">Plotly</div>
                <div className="skill">NLTK</div>
                <div className="skill">SpaCy</div>
                <div className="skill">Scikit learn</div>
                <div className="skill">NLTK</div>
                <div className="skill">MlFlow</div>
                <div className="skill">Docker</div>
                <div className="skill">Elastic search</div>
                <div className="skill">Azure</div>
            </div>
        </section>
    );
}

export default Skills;