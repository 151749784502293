import React from 'react';
import yourImage from '../me.jpg'; // Import your image
import './About.css';

function About() {
    return (
        <section id="about">
            <div className="about-content">
                <div className="about-text">
                    <h2>About Me</h2>
                    <p>
                        As a data scientist at XLAB, I work with and train large language models and apply them to practical scenarios,
                        such as text summarization, sentiment analysis, and chatbot development. I use huggingface, a leading open-source
                        library for natural language processing, to build and deploy robust and scalable NLP applications.

                        I have a deep interest in the software development aspect of machine learning and possess a research-oriented mindset,
                        constantly exploring new techniques and solutions. I graduated with a bachelor's degree in computer science from the
                        University of Ljubljana, where I also worked as a student developer at XLAB and IPLUS, gaining valuable experience in
                        software engineering, data analysis, and ML pipelines. My goal is to leverage my skills and knowledge to create innovative
                        and impactful NLP products and services.
                    </p>
                </div>
                <div className="about-image">
                    <img src={yourImage} alt="Aljaž Potočnik" />
                </div>
            </div>
        </section>
    );
}

export default About;