import React from 'react';
import About from './components/About';
import Footer from './components/Footer';
import Header from './components/Header';
import Projects from './components/Projects';
import Skills from './components/Skills';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Header />
        <About />
        <Projects />
        <Skills />
        <Footer />
      </div>
    </div>
  );
}

export default App;
